import * as React from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Sheet from "@mui/joy/Sheet";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import Radio, { radioClasses } from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Input from "@mui/joy/Input";
import Textarea from "@mui/joy/Textarea";
import Select, { selectClasses } from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";

interface MyComponentProps {
  getReasonData: (data: any) => any;
  active: boolean;
  setActive: Function;
}

const ReasonComponent: React.FC<MyComponentProps> = ({
  getReasonData,
  active,
  setActive,
}) => {
  const [selectedOption, setSelectedOption] = React.useState("");
  const [selectedEduOption, setEduSelectedOption] = React.useState("");
  const [selectedWeldingOption, setWeldingOption] = React.useState("");
  const [studyType, setStudyType] = React.useState("");

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleEduOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEduSelectedOption(event.target.value);
    setEducation({ ...education, education_type: event.target.value });
  };
  const handleWeldingOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setWeldingOption(event.target.value);
    setEducation({ ...education, education_salary_type: event.target.value });
  };
  const [work, setWork] = React.useState({
    reason: "Work",
    message: "",
  });

  const [other, setOther] = React.useState({
    reason: "Other",
    message: "",
  });

  const [visa, setVisa] = React.useState({
    reason: "Visa",
    illegal_income_amount: "",
    violation_fine_amount: "",
    is_illegal_income_amount: false,
    is_violation_fine_amount: false,
  });

  const [study, setStudy] = React.useState({
    reason: "Study",
    study_type: "",
    study_message: "",
  });

  const [education, setEducation] = React.useState({
    reason: "Education",
    education_type: "",
    education_salary_type: "",
    can_tig_welding: "",
    education_date: "",
    type_of_welding: "",
    education_time: "",
    autocad: "",
  });

  const handleInputChangeEducation = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setEducation({ ...education, [name]: value });
  };

  React.useEffect(() => {
    if (selectedOption === "Education") {
      getReasonData(education);
    }
    if (selectedOption === "Work") {
      getReasonData(work);
    }
    if (selectedOption === "Study") {
      getReasonData(study);
    }
    if (selectedOption === "Visa") {
      getReasonData(visa);
    }
    if (selectedOption === "Other") {
      getReasonData(other);
    }
    if (active) {
      setActive(false);
      setSelectedOption("");
      setEduSelectedOption("");
      setWeldingOption("");
      setStudyType("");
      setWork({ reason: "Work", message: "" });
      setOther({ reason: "Other", message: "" });
      setVisa({
        reason: "Visa",
        illegal_income_amount: "",
        violation_fine_amount: "",
        is_illegal_income_amount: false,
        is_violation_fine_amount: false,
      });
      setStudy({
        reason: "Study",
        study_type: "",
        study_message: "",
      });
      setEducation({
        reason: "Education",
        education_type: "",
        education_salary_type: "",
        can_tig_welding: "",
        education_date: "",
        type_of_welding: "",
        education_time: "",
        autocad: "",
      });
    }
  }, [
    selectedOption,
    work,
    visa,
    study,
    education,
    selectedEduOption,
    selectedWeldingOption,
    other,
    active,
    setActive,
    getReasonData,
  ]);

  const [t] = useTranslation("global");

  return (
    <div>
      <FormControl required>
        <FormLabel>
          {/* Reason (Murojaat sababi) */}
          {t("reason.main")}
        </FormLabel>
        <RadioGroup
          aria-label="platform"
          value={selectedOption}
          overlay
          name="platform"
          sx={{
            flexDirection: "row",

            gap: 1,
            [`& .${radioClasses.checked}`]: {
              [`& .${radioClasses.action}`]: {
                inset: -1,
                border: "3px solid",
                borderColor: "primary.500",
              },
            },
            [`& .${radioClasses.radio}`]: {
              display: "contents",
              "& > svg": {
                zIndex: 2,
                position: "absolute",
                top: "-8px",
                right: "-8px",
                bgcolor: "background.surface",
                borderRadius: "50%",
              },
            },
          }}
          onChange={handleOptionChange}
        >
          {["Education", "Work", "Study", "Other"].map((value) => (
            <Sheet
              key={value}
              variant="outlined"
              sx={{
                borderRadius: "md",
                boxShadow: "sm",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                p: 2,
                flex: 1,
              }}
            >
              <Radio
                id={value}
                value={value}
                checkedIcon={<CheckCircleRoundedIcon />}
              />
              <FormLabel htmlFor={value}>{value}</FormLabel>
            </Sheet>
          ))}
        </RadioGroup>
      </FormControl>
      {selectedOption === "Education" && (
        <div>
          <FormControl required>
            <FormLabel>
              {/* Education */}
              {t("reason.education")}
            </FormLabel>
            <RadioGroup
              aria-label="platform"
              defaultValue="Website"
              overlay
              name="platform"
              sx={{
                flexDirection: "row",
                gap: 2,
                [`& .${radioClasses.checked}`]: {
                  [`& .${radioClasses.action}`]: {
                    inset: -1,
                    border: "3px solid",
                    borderColor: "primary.500",
                  },
                },
                [`& .${radioClasses.radio}`]: {
                  display: "contents",
                  "& > svg": {
                    zIndex: 2,
                    position: "absolute",
                    top: "-8px",
                    right: "-8px",
                    bgcolor: "background.surface",
                    borderRadius: "50%",
                  },
                },
              }}
              onChange={handleEduOptionChange}
            >
              {["Welding", "Autocad"].map((value) => (
                <Sheet
                  key={value}
                  variant="outlined"
                  sx={{
                    borderRadius: "md",

                    boxShadow: "sm",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 1.5,
                    p: 2,
                    flex: 1,
                    //   minWidth: 120,
                  }}
                >
                  <Radio
                    id={value}
                    value={value}
                    checkedIcon={<CheckCircleRoundedIcon />}
                  />

                  <FormLabel htmlFor={value}>{value}</FormLabel>
                </Sheet>
              ))}
            </RadioGroup>
          </FormControl>
          {selectedEduOption === "Welding" && (
            <div>
              <FormControl>
                <FormLabel>
                  {t("education.welding")}
                  {/* Welding (Svarka) */}
                </FormLabel>
                <RadioGroup
                  aria-label="platform"
                  defaultValue="Website"
                  overlay
                  name="platform"
                  sx={{
                    flexDirection: "row",
                    gap: 2,
                    [`& .${radioClasses.checked}`]: {
                      [`& .${radioClasses.action}`]: {
                        inset: -1,
                        border: "3px solid",
                        borderColor: "primary.500",
                      },
                    },
                    [`& .${radioClasses.radio}`]: {
                      display: "contents",
                      "& > svg": {
                        zIndex: 2,
                        position: "absolute",
                        top: "-8px",
                        right: "-8px",
                        bgcolor: "background.surface",
                        borderRadius: "50%",
                      },
                    },
                  }}
                  onChange={handleWeldingOptionChange}
                >
                  {["Free", "Paid"].map((value) => (
                    <Sheet
                      key={value}
                      variant="outlined"
                      sx={{
                        borderRadius: "md",

                        boxShadow: "sm",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 1.5,
                        p: 2,
                        flex: 1,
                        //   minWidth: 120,
                      }}
                    >
                      <Radio
                        id={value}
                        value={value}
                        checkedIcon={<CheckCircleRoundedIcon />}
                      />

                      <FormLabel htmlFor={value}>{value}</FormLabel>
                    </Sheet>
                  ))}
                </RadioGroup>
              </FormControl>
              {selectedWeldingOption === "Free" && (
                <div>
                  <FormControl>
                    <FormLabel sx={{ marginTop: 2 }}>
                      {t("education.firstQ")}
                      {/* Have you ever learned TIG(Tungsten Inert Gas) welding?{" "}
                      <br /> TIG svarka o'rganganmisiz ? */}
                    </FormLabel>
                    <RadioGroup
                      defaultValue="medium"
                      name="radio-buttons-group"
                    >
                      <div>
                        <Radio
                          value="Yes"
                          name="can_tig_welding"
                          onChange={handleInputChangeEducation}
                          label={t("showAnswer.yes")}
                          color="primary"
                        />
                        <Radio
                          value="No"
                          name="can_tig_welding"
                          onChange={handleInputChangeEducation}
                          label={t("showAnswer.no")}
                          color="danger"
                          sx={{ marginLeft: 2 }}
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel sx={{ marginTop: 2 }}>
                      {t("education.secondQ")}
                      {/* When do you plan to learn?
                      <br />
                      Qachondan boshlashni reja qilyapsiz? ` */}
                    </FormLabel>
                    <RadioGroup
                      defaultValue="medium"
                      name="radio-buttons-group"
                    >
                      <div style={{ display: "grid" }}>
                        <Radio
                          name="education_date"
                          onChange={handleInputChangeEducation}
                          value="9-oy ~ 10-oy"
                          label="September ~ October"
                          color="primary"
                        />
                        <Radio
                          name="education_date"
                          onChange={handleInputChangeEducation}
                          value="11-oy ~ 12-oy"
                          label="November ~ December"
                          color="primary"
                          sx={{ marginTop: 2 }}
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                </div>
              )}
              {selectedWeldingOption === "Paid" && (
                <>
                  <FormControl>
                    <FormLabel sx={{ marginTop: 2 }}>
                      {t("education.thirdQ")}
                      {/* Types of Welding <br />
                      Svarka turi! */}
                    </FormLabel>
                    <RadioGroup
                      defaultValue="medium"
                      name="radio-buttons-group"
                    >
                      <Radio
                        value="TIG(Argon)"
                        name="type_of_welding"
                        onChange={handleInputChangeEducation}
                        label="TIG(Argon)"
                        color="primary"
                      />
                      <Radio
                        value="C02"
                        name="type_of_welding"
                        onChange={handleInputChangeEducation}
                        label="C02"
                        color="primary"
                      />
                      <Radio
                        value="Electrode AK"
                        name="type_of_welding"
                        onChange={handleInputChangeEducation}
                        label="Electrode AK"
                        color="primary"
                      />
                      <Radio
                        value="Aluminum"
                        name="type_of_welding"
                        onChange={handleInputChangeEducation}
                        label="Aluminum"
                        color="primary"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel sx={{ marginTop: 2 }}>
                      {t("education.time")}
                      {/* Time (Vaqt): */}
                    </FormLabel>
                    <RadioGroup
                      defaultValue="medium"
                      name="radio-buttons-group"
                    >
                      <div>
                        <Radio
                          value="14:00~18:00"
                          name="education_time"
                          onChange={handleInputChangeEducation}
                          label="14:00~18:00"
                          color="primary"
                        />
                        <Radio
                          value="18:00~22:00"
                          name="education_time"
                          onChange={handleInputChangeEducation}
                          label="18:00~22:00"
                          color="primary"
                          sx={{
                            marginLeft: 2,
                          }}
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                </>
              )}
            </div>
          )}
          {selectedEduOption === "Autocad" && (
            <FormControl>
              <FormLabel>Autocad</FormLabel>
              <Input
                type="text"
                name="autocad"
                value={education.autocad}
                onChange={handleInputChangeEducation}
                placeholder="Enter your company"
              />
            </FormControl>
          )}
        </div>
      )}
      {selectedOption === "Work" && (
        <FormControl>
          <FormLabel sx={{ marginTop: 2 }}>
            {t("work.reason")}

            {/* Please, write a reason of your appeal!
            <br />
            Iltimos, murojaatingizni sababini yozib qoldiring! */}
          </FormLabel>
          <Textarea
            placeholder="...."
            value={work.message}
            onChange={(e) => {
              setWork({ reason: "Work", message: e.target.value });
            }}
            minRows={2}
            sx={{
              "--Textarea-focusedInset": "var(--any, )",
              "--Textarea-focusedThickness": "0.25rem",
              "--Textarea-focusedHighlight": "rgba(13,110,253,.25)",
              "&::before": {
                transition: "box-shadow .15s ease-in-out",
              },
              "&:focus-within": {
                borderColor: "#86b7fe",
              },
            }}
          />
        </FormControl>
      )}
      {selectedOption === "Study" && (
        <>
          <FormControl>
            <FormLabel sx={{ marginTop: 2 }}>
              {t("study.main")}

              {/* Choose your degree <br /> (Talim darajangizni belgilang!): */}
            </FormLabel>
            <Select
              placeholder="Bachelor..."
              value={studyType}
              onChange={(e, newValue) => {
                setStudyType(newValue as string);
                setStudy({ ...study, study_type: newValue as string });
              }}
              indicator={<KeyboardArrowDown />}
              sx={{
                [`& .${selectClasses.indicator}`]: {
                  transition: "0.2s",
                  [`&.${selectClasses.expanded}`]: {
                    transform: "rotate(-180deg)",
                  },
                },
              }}
            >
              <Option value="Language course">
                {t("study.level.lang")}
                {/* Language course (Til kursi) */}
              </Option>
              <Option value="Bachelor">
                {/* Bachelor (Bakalavr) */}
                {t("study.level.bachelor")}
              </Option>
              <Option value="Master's">
                {t("study.level.master")}
                {/* Master's (Magistr) */}
              </Option>

              <Option value="Postgraduate">
                {t("study.level.phd")}
                {/* Postgraduate (Doktarantura) */}
              </Option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel sx={{ marginTop: 2 }}>
              {t("study.reason")}
              {/* Please, write a reason of your appeal!
              <br />
              Iltimos, murojaatingizni sababini yozib qoldiring! */}
            </FormLabel>
            <Textarea
              placeholder="...."
              value={study.study_message}
              onChange={(e) => {
                setStudy({ ...study, study_message: e.target.value });
              }}
              minRows={2}
              sx={{
                "--Textarea-focusedInset": "var(--any, )",
                "--Textarea-focusedThickness": "0.25rem",
                "--Textarea-focusedHighlight": "rgba(13,110,253,.25)",
                "&::before": {
                  transition: "box-shadow .15s ease-in-out",
                },
                "&:focus-within": {
                  borderColor: "#86b7fe",
                },
              }}
            />
          </FormControl>
        </>
      )}
      {/* {selectedOption === "Visa" && (
        <>
          <FormControl>
            <FormLabel sx={{ marginTop: 2 }}>{t("visa.firstQ")}</FormLabel>
            <RadioGroup defaultValue="medium" name="radio-buttons-group">
              <div>
                <Radio
                  value="primary11"
                  label={t("showAnswer.yes")}
                  color="primary"
                  onChange={() => {
                    setVisa({ ...visa, is_illegal_income_amount: true });
                  }}
                />
                <Radio
                  value="primary22"
                  label={t("showAnswer.no")}
                  onChange={() => {
                    setVisa({
                      ...visa,
                      illegal_income_amount: "",
                      is_illegal_income_amount: false,
                    });
                  }}
                  color="primary"
                  sx={{ marginLeft: 2 }}
                />
              </div>
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel sx={{ marginTop: 2 }}>Enter amount of money!</FormLabel>
            <Input
              type="text"
              name="illegal_income_amount"
              value={visa.illegal_income_amount}
              disabled={!visa.is_illegal_income_amount}
              onChange={(e) => {
                setVisa({ ...visa, illegal_income_amount: e.target.value });
              }}
              placeholder="1.0000.000w"
            />
          </FormControl>
          <FormControl>
            <FormLabel sx={{ marginTop: 2 }}>{t("visa.secondQ")}</FormLabel>
            <RadioGroup defaultValue="medium" name="radio-buttons-group">
              <div>
                <Radio
                  value="primary33"
                  label={t("showAnswer.yes")}
                  onChange={() => {
                    setVisa({ ...visa, is_violation_fine_amount: true });
                  }}
                  color="primary"
                />
                <Radio
                  value="primary44"
                  label={t("showAnswer.no")}
                  onChange={() => {
                    setVisa({
                      ...visa,
                      violation_fine_amount: "",
                      is_violation_fine_amount: false,
                    });
                  }}
                  color="primary"
                  sx={{ marginLeft: 2 }}
                />
              </div>
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel sx={{ marginTop: 2 }}>{t("visa.thirdQ")}</FormLabel>
            <Input
              type="text"
              name="violation_fine_amount"
              value={visa.violation_fine_amount}
              disabled={!visa.is_violation_fine_amount}
              onChange={(e) => {
                setVisa({ ...visa, violation_fine_amount: e.target.value });
              }}
              placeholder="1.0000.000w"
            />
          </FormControl>
        </>
      )} */}
      {selectedOption === "Other" && (
        <FormControl>
          <FormLabel sx={{ marginTop: 2 }}>{t("other.reason")}</FormLabel>
          <Textarea
            placeholder="...."
            value={other.message}
            onChange={(e) => {
              setOther({ reason: "Other", message: e.target.value });
            }}
            minRows={2}
            sx={{
              "--Textarea-focusedInset": "var(--any, )",
              "--Textarea-focusedThickness": "0.25rem",
              "--Textarea-focusedHighlight": "rgba(13,110,253,.25)",
              "&::before": {
                transition: "box-shadow .15s ease-in-out",
              },
              "&:focus-within": {
                borderColor: "#86b7fe",
              },
            }}
          />
        </FormControl>
      )}
    </div>
  );
};

export default ReasonComponent;
