import * as React from "react";
import { CssVarsProvider, useColorScheme } from "@mui/joy/styles";
import GlobalStyles from "@mui/joy/GlobalStyles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Select, { selectClasses } from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import IconButton, { IconButtonProps } from "@mui/joy/IconButton";
import Link from "@mui/joy/Link";
import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import logo from "../assets/logo.svg";
import darkLogo from "../assets/darkLogo.svg";
import { ImgWrapper } from "./style";
import Selects from "react-select";
import countryList from "react-select-country-list";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Person from "@mui/icons-material/Person";
import Apartment from "@mui/icons-material/Apartment";
import axios from "axios";
import ReasonComponent from "./reason";
import { toast } from "react-toastify";
import TranslateComponent from "./translate";

import SvgIcon from "@mui/joy/SvgIcon";
import { Checkbox, styled } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

function ColorSchemeToggle(props: IconButtonProps) {
  const { onClick, ...other } = props;
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => setMounted(true), []);

  return (
    <IconButton
      aria-label="toggle light/dark mode"
      size="sm"
      variant="outlined"
      disabled={!mounted}
      onClick={(event) => {
        setMode(mode === "light" ? "dark" : "light");
        onClick?.(event);
      }}
      {...other}
    >
      {mode === "light" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  );
}

interface FormValues {
  name: string;
  phone: string;
  country: { label: string; value: string };
  position: string;
  place_of_study: string;
  workplace: string;
  gender: string;
  birthday: string;
  visa: string;
  address: string;
  language: string;
  resume: File | null;
}

function DataComponent() {
  const { mode } = useColorScheme();
  const [otp, setOtp] = React.useState<string>("");
  const [otp_id, setOtpID] = React.useState<string>("");
  const [verifyNumber, setVerifyNumber] = React.useState<boolean>(false);
  const [otpVisible, setOtpVisible] = React.useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [checkPrivacy, setCheckPrivacy] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues>({
    name: "",
    phone: "",
    country: { label: "Select...", value: "" },
    position: "",
    place_of_study: "",
    workplace: "",
    gender: "",
    birthday: "",
    visa: "",
    address: "",
    resume: null,
    language: "English",
  });
  const [source, setSource] = useState<string | null>(null);

  const options = React.useMemo(() => countryList().getData(), []);
  const [selectedOption, setSelectedOption] = React.useState("");
  const [reason, setReason] = React.useState({});
  const [t, i18n] = useTranslation("global");

  const handleChangeLanguage = (lang: string, label: string) => {
    i18n.changeLanguage(lang);
    setFormValues({ ...formValues, language: label });
  };
  const changeHandler = (selectedOption: any) => {
    setFormValues({ ...formValues, country: selectedOption });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = e.target;
    if (name === "resume" && files) {
      setFormValues({ ...formValues, resume: files[0] });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleSelectChange = (e: any) => {
    setFormValues({ ...formValues, visa: e?.target?.innerText });
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedOption(value);
    if (value === "Other") {
      setFormValues({
        ...formValues,
        workplace: "",
        place_of_study: "",
        position: "Other",
      });
    }
    if (value === "Employee") {
      setFormValues({
        ...formValues,
        place_of_study: "",
        position: "Employee",
      });
    }
    if (value === "Student") {
      setFormValues({
        ...formValues,
        workplace: "",
        position: "Student",
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!checkPrivacy) {
      toast.error("Please allow us to use your personal information!");
      return;
    }

    // if (!verifyNumber) {
    //   toast.error("Phone number not verification!");
    //   return;
    // }

    const formData = new FormData();
    formData.append("name", formValues.name);
    formData.append("phone", formValues.phone);
    formData.append("position", formValues.position);
    formData.append("place_of_study", formValues.place_of_study);
    formData.append("workplace", formValues.workplace);
    formData.append("gender", formValues.gender);
    formData.append("birthday", formValues.birthday);
    formData.append("visa", formValues.visa);
    formData.append("address", formValues.address);
    formData.append("language", formValues.language);
    formData.append("country", formValues.country.label);
    formData.append("reason", JSON.stringify(reason));
    if (formValues.resume) {
      formData.append("file", formValues.resume);
    }
    if (source && source?.length === 24) {
      formData.append("source_id", source);
    }

    try {
      const response = await axios.post(
        "https://api.urconnection.co.kr/api/user-data/sign-up",
        formData
      );
      if (response.data.success) {
        toast.success("Successfully send!");
        setActive(true);
        setCheckPrivacy(false);
        setVerifyNumber(false);
        setFormValues({
          name: "",
          phone: "",
          country: { label: "Select...", value: "" },
          position: "",
          place_of_study: "",
          workplace: "",
          gender: "",
          birthday: "",
          visa: "",
          address: "",
          resume: null,
          language: "English",
        });
        setSelectedOption("");
      }
    } catch (error: any) {
      if (!error.response?.data?.success)
        toast.error(error.response.data?.error?.msg);
    }
  };

  const getReasonData = (data: any) => {
    setReason(data);
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#333" : "#222",
      borderColor: state.isFocused ? "#aaa" : "#444",
      color: "#fff",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "#fff",
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: "#333",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#444" : "#333",
      color: state.isSelected ? "#fff" : "#aaa",
    }),
  };

  useEffect(() => {
    const fetchData = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const sourceParam = urlParams.get("source");
      setSource(sourceParam);
      if (sourceParam && sourceParam.length === 24) {
        try {
          await axios.get(
            `https://api.urconnection.co.kr/api/sns-link/open/${sourceParam}`,
            {
              withCredentials: true,
            }
          );
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchData();
  }, []);

  const sendVerification = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://api.urconnection.co.kr/api/user-data/send-otp",
        { phone: formValues.phone }
      );
      if (response?.data?.success) {
        setOtpVisible(true);
        toast.success(response.data.data.remarks);
        setOtpID(response.data.data.verification_id);
      }
    } catch (error: any) {
      if (!error.response?.data?.success)
        toast.error(error.response.data?.error?.msg);
    }
  };

  const verifyOtp = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://api.urconnection.co.kr/api/user-data/verify-otp",
        {
          phone: formValues.phone,
          verification_id: otp_id,
          verification_code: otp,
        }
      );
      if (response?.data?.success) {
        setVerifyNumber(true);
        setOtpVisible(false);
        setOtp("");
        setOtpID("");
        toast.success(response?.data?.msg);
      } else {
        toast.error(response.data?.msg);
      }
    } catch (error: any) {
      if (!error.response?.data?.success)
        toast.error(error.response.data?.error?.msg);
    }
  };

  return (
    <CssVarsProvider defaultMode="light" disableTransitionOnChange>
      <CssBaseline />

      <GlobalStyles
        styles={{
          ":root": {
            "--Form-maxWidth": "800px",
            "--Transition-duration": "0.4s", // set to `none` to disable transition
          },
        }}
      />

      <Box
        sx={(theme) => ({
          transition: "width var(--Transition-duration)",
          transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
          position: "relative",
          zIndex: 1,
          display: "flex",
          justifyContent: "flex-end",
          backdropFilter: "blur(12px)",
          backgroundColor: "rgba(255 255 255 / 0.2)",
          [theme.getColorSchemeSelector("dark")]: {
            backgroundColor: "rgba(19 19 24 / 0.4)",
          },
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            width: "100%",
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ gap: 2, display: "flex", alignItems: "center" }}>
              <ImgWrapper src={mode === "light" ? logo : darkLogo} />
            </Box>

            <ColorSchemeToggle />
          </Box>
          <Box
            component="main"
            sx={{
              my: "auto",
              py: 2,
              pb: 5,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: 400,
              maxWidth: "100%",
              mx: "auto",
              borderRadius: "sm",
              "& form": {
                display: "flex",
                flexDirection: "column",
                gap: 2,
              },
              [`& .MuiFormLabel-asterisk`]: {
                visibility: "hidden",
              },
            }}
          >
            <>
              <TranslateComponent handleChangeLanguage={handleChangeLanguage} />
            </>
            <Stack gap={4} sx={{ mb: 2 }}>
              <Stack gap={1}>
                <Typography
                  component="h1"
                  level="h3"
                  sx={{ textAlign: "center" }}
                >
                  {t("MainTitle.Title")}
                  {/* Do you need a job? */}
                </Typography>
                <Typography level="body-md" sx={{ textAlign: "center" }}>
                  {t("MainTitle.subTitle")}
                  {/* Are you interested in our company? */}
                  <Link
                    href="https://urconnection.co.kr/"
                    level="title-md"
                    sx={{ marginLeft: 2 }}
                  >
                    Homepage
                  </Link>
                </Typography>
              </Stack>
            </Stack>
            <Divider
              sx={(theme) => ({
                [theme.getColorSchemeSelector("light")]: {
                  color: { xs: "#FFF", md: "text.tertiary" },
                },
              })}
            />
            <Stack gap={4} sx={{ mt: 2 }}>
              <form onSubmit={handleSubmit}>
                <FormControl required>
                  <FormLabel>{t("mainInfo.name")}</FormLabel>
                  <Input
                    type="text"
                    name="name"
                    placeholder="Muhammad"
                    value={formValues.name}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel required>
                    {/* Gender (Jins) */}
                    {t("mainInfo.gender")}
                  </FormLabel>
                  <RadioGroup
                    name="gender"
                    value={formValues.gender}
                    onChange={(e) => handleInputChange(e as any)}
                    sx={{ my: 1 }}
                  >
                    <div style={{ display: "flex" }}>
                      <Radio value="female" label={t("gender.female")} />

                      <Radio
                        value="male"
                        label={t("gender.male")}
                        sx={{ marginLeft: 10 }}
                      />
                    </div>
                  </RadioGroup>
                </FormControl>
                <FormControl required>
                  <FormLabel>
                    {/* Country (Davlat) */}
                    {t("mainInfo.country")}
                  </FormLabel>
                  <Selects
                    options={options}
                    styles={mode === "light" ? {} : customStyles}
                    value={formValues.country}
                    onChange={changeHandler}
                  />
                </FormControl>
                <FormControl required>
                  <FormLabel>
                    {/* Phone (Telefon nomer) */}

                    {t("mainInfo.phone")}
                  </FormLabel>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        width:
                          formValues.phone && !verifyNumber ? "65%" : "100%",
                      }}
                    >
                      <Input
                        type="tel"
                        name="phone"
                        placeholder="010 2892 3032"
                        disabled={verifyNumber}
                        value={formValues.phone}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div>
                      {formValues.phone && !verifyNumber ? (
                        <Button onClick={sendVerification} type="button">
                          Tasdiqlash
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </FormControl>
                {otpVisible ? (
                  <FormControl required>
                    <FormLabel>
                      Verify phone number (tasdiq kodni kiriting)
                    </FormLabel>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: otp ? "85%" : "100%" }}>
                        <Input
                          type="number"
                          name="otp"
                          placeholder="1234"
                          value={otp}
                          onChange={(e) => {
                            setOtp(e.target.value);
                          }}
                        />
                      </div>
                      <div>
                        {otp ? (
                          <Button
                            onClick={verifyOtp}
                            type="button"
                            sx={{ marginLeft: 2 }}
                          >
                            Tasdiqlash
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </FormControl>
                ) : (
                  ""
                )}
                <FormControl required>
                  <FormLabel>
                    {/* Birthday (To'g'ilgan sana) */}

                    {t("mainInfo.birthday")}
                  </FormLabel>
                  <Input
                    type="date"
                    name="birthday"
                    value={formValues.birthday}
                    onChange={handleInputChange}
                    slotProps={{
                      input: {
                        min: "1950-01-02",
                      },
                    }}
                  />
                </FormControl>
                <FormControl required>
                  <FormLabel>{t("mainInfo.Visa")}</FormLabel>
                  <Select
                    placeholder="Select a VISA"
                    name="visa"
                    value={formValues.visa || ""}
                    onChange={handleSelectChange}
                    indicator={<KeyboardArrowDown />}
                    sx={{
                      [`& .${selectClasses.indicator}`]: {
                        transition: "0.2s",
                        [`&.${selectClasses.expanded}`]: {
                          transform: "rotate(-180deg)",
                        },
                      },
                    }}
                  >
                    <Option value="E-7 (특정활동)">E-7 (특정활동)</Option>
                    <Option value="E-8 (계절근로)">E-8 (계절근로)</Option>
                    <Option value="E-9 (비전문취업)">E-9 (비전문취업)</Option>
                    <Option value="D-2 (유학)">D-2 (유학)</Option>
                    <Option value="D-4 (일반연수)">D-4 (일반연수)</Option>
                    <Option value="D-8 (기업투자)">D-8 (기업투자)</Option>
                    <Option value="D-9 (무역경영)">D-9 (무역경영)</Option>
                    <Option value="D-10 (구직)">D-10 (구직)</Option>
                    <Option value="F-1 (방문동거)">F-1 (방문동거)</Option>
                    <Option value="F-2 (거주)">F-2 (거주)</Option>
                    <Option value="F-3 (동반)">F-3 (동반)</Option>
                    <Option value="F-4 (재외동포)">F-4 (재외동포)</Option>
                    <Option value="F-5 (영주)">F-5 (영주)</Option>
                    <Option value="F-6 (결혼이민)">F-6 (결혼이민)</Option>
                    <Option value="H-1 (관광취업)">H-1 (관광취업)</Option>
                    <Option value="H-2 (방문취업)">H-2 (방문취업)</Option>
                    <Option value="C-3 (단기방문)">C-3 (단기방문)</Option>
                    <Option value="G-1 (기타)">G-1 (기타)</Option>
                    <Option value="E-1 (교수)">E-1 (교수)</Option>
                    <Option value="E-3 (연구)">E-3 (연구)</Option>
                    <Option value="A-1 (외교)">A-1 (외교)</Option>
                    <Option value="D-5 (취재)">D-5 (취재)</Option>
                    <Option value="A-2 (공무)">A-2 (공무)</Option>
                    <Option value="D-6 (종교)">D-6 (종교)</Option>
                    <Option value="D-7 (협정)">D-7 (협정)</Option>
                    <Option value="B-1 (사증면제)">B-1 (사증면제)</Option>
                    <Option value="E-10 (선원취업)">E-10 (선원취업)</Option>
                    <Option value="B-2 (관광통과)">B-2 (관광통과)</Option>
                    <Option value="C-1 (일시취재)">C-1 (일시취재)</Option>
                    <Option value="C-4 (단기취업)">C-4 (단기취업)</Option>
                    <Option value="E-2 (회화지도)">E-2 (회화지도)</Option>
                    <Option value="D-1 (문화예술)">D-1 (문화예술)</Option>
                    <Option value="E-4 (기술지도)">E-4 (기술지도)</Option>
                    <Option value="D-3 (기술연수)">D-3 (기술연수)</Option>
                    <Option value="E-5 (전문직업)">E-5 (전문직업)</Option>
                    <Option value="E-6 (예술흥행)">E-6 (예술흥행)</Option>
                    <Option value="Other">Other</Option>
                  </Select>
                </FormControl>
                <FormControl required>
                  <FormLabel>
                    {/* Address (Manzil) */}
                    {t("mainInfo.Address")}
                  </FormLabel>
                  <Input
                    type="text"
                    name="address"
                    placeholder="서울(Seoul)"
                    value={formValues.address}
                    onChange={handleInputChange}
                  />
                </FormControl>

                <RadioGroup
                  aria-label="Your plan"
                  name="people"
                  value={selectedOption}
                  onChange={handleOptionChange}
                >
                  <List
                    sx={{
                      minWidth: 240,
                      "--List-gap": "0.5rem",
                      "--ListItem-paddingY": "1rem",
                      "--ListItem-radius": "8px",
                      "--ListItemDecorator-size": "32px",
                    }}
                  >
                    {["Student", "Employee", "Other"].map((item) => (
                      <ListItem
                        variant="outlined"
                        key={item}
                        sx={{ boxShadow: "sm" }}
                      >
                        <ListItemDecorator>
                          {item === "Student" ? <Person /> : <Apartment />}
                        </ListItemDecorator>
                        <Radio
                          overlay
                          value={item}
                          label={item}
                          sx={{ flexGrow: 1, flexDirection: "row-reverse" }}
                          slotProps={{
                            action: ({ checked }) => ({
                              sx: (theme) => ({
                                ...(checked && {
                                  inset: -1,
                                  border: "2px solid",
                                  borderColor: theme.vars.palette.primary[500],
                                }),
                              }),
                            }),
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </RadioGroup>

                {selectedOption === "Student" && (
                  <FormControl required>
                    <FormLabel>
                      {/* University (Institut) */}
                      {t("container.student")}
                    </FormLabel>
                    <Input
                      type="text"
                      name="place_of_study"
                      placeholder="Enter your university"
                      value={formValues.place_of_study}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                )}
                {selectedOption === "Employee" && (
                  <FormControl required>
                    <FormLabel>
                      {/* Company (Ishxona) */}
                      {t("container.employee")}
                    </FormLabel>
                    <Input
                      type="text"
                      name="workplace"
                      placeholder="Enter your company"
                      value={formValues.workplace}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                )}
                <FormLabel>
                  {/* Please upload your resume */}
                  {t("resume.note")}
                </FormLabel>
                <Button
                  component="label"
                  role={undefined}
                  tabIndex={-1}
                  variant="outlined"
                  color="neutral"
                  startDecorator={
                    <SvgIcon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                        />
                      </svg>
                    </SvgIcon>
                  }
                >
                  {formValues.resume ? "Uploaded" : "Upload Resume"}
                  <VisuallyHiddenInput
                    type="file"
                    name="resume"
                    onChange={handleInputChange}
                  />
                </Button>
                <ReasonComponent
                  getReasonData={getReasonData}
                  active={active}
                  setActive={setActive}
                />
                <Checkbox
                  onChange={(e) => {
                    setCheckPrivacy(e.target.checked);
                  }}
                  checked={checkPrivacy}
                  label={<React.Fragment>{t("privacy")}</React.Fragment>}
                />

                <Stack gap={4} sx={{ mt: 2 }}>
                  <Button type="submit" fullWidth>
                    {t("sendButton.done")}
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Box>
        </Box>
      </Box>
    </CssVarsProvider>
  );
}

export default function App() {
  return (
    <CssVarsProvider defaultMode="light" disableTransitionOnChange>
      <DataComponent />
    </CssVarsProvider>
  );
}
